import {
  Box,
  BoxProps,
  Button,
  Container,
  Flex,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import Image from 'next/legacy/image'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { Stars } from 'components/icons/Stars'
import { Route } from 'constants/routes'
import { useAuth } from 'providers/UserProvider'

import ContactUsModal from './ContactUs/ContactUsModal'

interface HeaderSectionProps extends BoxProps {
  isBrandPage?: boolean
}

const HeaderSection = ({ isBrandPage, ...rest }: HeaderSectionProps) => {
  const { t } = useTranslation('common')
  const { userData, signOut } = useAuth()
  const router = useRouter()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box w="full" position="relative" {...rest}>
      <Container maxW="1110px" position="relative" centerContent>
        <Box mt={{ base: '64px', lg: '120px' }} maxW="500px">
          <Text
            fontFamily="Cocogoose Pro"
            fontSize={{ base: '24px', md: '42px' }}
            lineHeight={{ base: '31px', md: '54px' }}
            color="brand.ultramarine"
            textAlign="center"
          >
            {t('COMMON_FOOTER_MOTTO1')}{' '}
            <Box
              as="span"
              fontFamily="Cocogoose Pro"
              fontSize={{ base: '24px', md: '42px' }}
              lineHeight={{ base: '31px', md: '54px' }}
              style={{ position: 'relative' }}
            >
              <span style={{ position: 'absolute', left: '33%' }}>
                <Stars w="43px" h="43px" position="relative" bottom="50px" />
              </span>
              {t('COMMON_FOOTER_MOTTO2')}
            </Box>{' '}
            {t('COMMON_FOOTER_MOTTO3')}
          </Text>
        </Box>
        <Text
          mt={{ base: '8px', lg: '16px' }}
          fontSize={{ base: '20px', md: '24px' }}
          lineHeight={{ base: '30px', md: '38px' }}
          textAlign="center"
        >
          {isBrandPage ? t('BRAND_PAGE_TEXT6') : t('VENUE_PAGE_TEXT6')}
        </Text>
        <VStack
          mt={{ base: '16px', lg: '32px' }}
          mb={{ base: '21px', lg: '50px' }}
          align="center"
          spacing={{ base: '22px', md: '24px' }}
        >
          <Flex direction={{ base: 'column', sm: 'row' }}>
            <Button
              w="auto"
              h="48px"
              mr={{ base: 0, sm: '16px' }}
              mb={{ base: '16px', sm: 0 }}
              fontFamily="Proxima Nova Semibold"
              fontSize="18px"
              lineHeight="28px"
              onClick={() => {
                void (async () => {
                  if (userData) {
                    await signOut(false)
                  }
                  void router.push(Route.SignUp())
                })()
              }}
            >
              {userData ? t('TRANSLATION_317') : t('VENUE_PAGE_TEXT4')}
            </Button>
            <Button
              variant="lightBlue"
              h="48px"
              fontFamily="Proxima Nova Semibold"
              fontSize="18px"
              lineHeight="28px"
              onClick={onOpen}
            >
              {t('VENUE_PAGE_TEXT8')}
            </Button>
            <ContactUsModal isOpen={isOpen} onClose={onClose} />
          </Flex>
          <HStack spacing={0}>
            <Text
              fontFamily="Proxima Nova SemiBold"
              fontSize={{ base: '14px', md: '18px' }}
              lineHeight="22px"
            >
              {t('VENUE_PAGE_TEXT9')}
            </Text>
            <Box
              w={{ base: '64px', md: '90px' }}
              h={{ base: '57px', md: '90px' }}
              display="block"
            >
              <Image
                alt="food and drink awards badge"
                width={120}
                height={120}
                src="/landingPage/food_and_drink_awards_badge.png"
                layout="responsive"
              />
            </Box>
          </HStack>
        </VStack>
      </Container>
    </Box>
  )
}

export default HeaderSection
