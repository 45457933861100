import { Flex, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'

export const DistributionCard = () => {
  const { t } = useTranslation('common')

  return (
    <Flex padding="24px" direction="column" gap="12px">
      <Text
        fontSize="lg"
        fontWeight="600"
        color="brand.secondary.ultramarine900"
      >
        {t('LandingPage.ParamountLiqourCooperation.Distribution.title')}
      </Text>
      <Text
        fontSize="sm"
        lineHeight="110%"
        fontWeight="400"
        color="brand.secondary.ultramarine800"
      >
        {t('LandingPage.ParamountLiqourCooperation.Distribution.description')}
      </Text>
    </Flex>
  )
}
