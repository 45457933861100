import { Box, Flex } from '@chakra-ui/react'
import Image from 'next/image'

import { ConnectCard } from './ConnectCard'
import { DistributionCard } from './DistributionCard'
import { MatchCard } from './MatchCard'

const CardData = {
  Match: {
    alt: 'Match',
    src: '/landingPage/paramountLiqourCards/match.png',
    card: () => <MatchCard />,
  },
  Connect: {
    alt: 'Connect',
    src: '/landingPage/paramountLiqourCards/connect.png',
    card: () => <ConnectCard />,
  },
  Distribution: {
    alt: 'Distribution',
    src: '/landingPage/paramountLiqourCards/distribution.png',
    card: () => <DistributionCard />,
  },
}

const CardDataKeys = Object.keys(CardData) as (keyof typeof CardData)[]

export const ParamountLiqourCard = () =>
  CardDataKeys.map((key) => (
    <Box
      key={key}
      flex="1 0 0"
      height="420px"
      bgColor="brand.secondary.ultramarine50"
      borderRadius="12px"
    >
      <Image
        alt={CardData[key].alt}
        height={272}
        width={351}
        src={CardData[key].src}
      />
      <Flex flexDir="column" gap="12px">
        {CardData[key].card()}
      </Flex>
    </Box>
  ))
