import { Badge, Flex, Text } from '@chakra-ui/react'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

export const MatchCard = () => {
  const { t } = useTranslation('common')

  return (
    <Flex padding="24px" direction="column" gap="12px">
      <Flex h="28px" justifyContent="space-between" alignItems="center">
        <Flex gap="5px" alignItems="center">
          <Image alt="Match" height={24} width={33.41} src="/icons/match.svg" />
          <Text
            fontSize="lg"
            fontWeight="600"
            color="brand.secondary.ultramarine900"
          >
            {t('LandingPage.ParamountLiqourCooperation.Match.title')}
          </Text>
        </Flex>

        <Badge variant="solid" color="brand.white" bgColor="brand.orange">
          {t('LandingPage.ParamountLiqourCooperation.Match.badge')}
        </Badge>
      </Flex>
      <Text
        fontSize="sm"
        lineHeight="110%"
        fontWeight="400"
        color="brand.secondary.ultramarine800"
      >
        {t('LandingPage.ParamountLiqourCooperation.Match.description')}
      </Text>
    </Flex>
  )
}
